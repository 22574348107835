import { Container, Row, Col } from "react-bootstrap";
import React, { useState, useRef, useEffect } from "react";
import "./style/navbar.css";
import "./style/basic.css";
import logo from "../assets/image/logo.svg";
import { Link, useLocation } from "react-router-dom";
import useScrollPosition from "@react-hook/window-scroll";
import {withNamespaces} from "react-i18next";
import Languageselector from "./Languageselector";
function Navbar({t}) {
  const [blurrednav, setblurrednav] = useState(false);
  const menu = useRef(null);
  const menu_bar = useRef(null);
  const blur = useRef(null);
  const navbar = useRef(null);
  const scrollY = useScrollPosition(120 /*frames per second*/);
  const location = useLocation();
  const toggleMenu = (nav) => {
    if (menu.current.classList.contains("open")) {
      menu.current.classList.remove("open");
      menu_bar.current.classList.remove("open");
      blur.current.classList.remove("open");
      navbar.current.classList.remove("open");
      if (nav == "blurred") {
        setblurrednav(true);
        menu_bar.current.classList.add("blue");
      }
      if (nav != "blurred" && nav != "exit") {
        menu_bar.current.classList.remove("blue");
        setblurrednav(false);
      }
    } else {
      navbar.current.classList.add("open");
      menu.current.classList.add("open");
      menu_bar.current.classList.add("open");
      blur.current.classList.add("open");
    }
  };
  useEffect(() => {
    if (scrollY > 5 || blurrednav || location.pathname != "/") {
      navbar.current.classList.add("headbar-blur");
      menu_bar.current.classList.add("blue");
    } else {
      navbar.current.classList.remove("headbar-blur");
      menu_bar.current.classList.remove("blue");
    }
  }, [scrollY, blurrednav]);
  return (
    <div className="navbar">
      <div className="flex-row" ref={navbar}>
        <div className="navlogo">
          <Link
            className="mt-2"
            to="/"
            onClick={() => {
              if (scrollY < 6) menu_bar.current.classList.remove("blue");
              setblurrednav(false);
            }}
          >
            <img src={logo} alt="Logo" className="logo-1" />
          </Link>
        </div>
        <div
          className="navburger"
          ref={menu_bar}
          onClick={() => toggleMenu("exit")}
        >
          <div className="navburger__slice"></div>
        </div>
      </div>
      <div className="nav-hide flex-column" ref={menu}>
        <div className="flex-column ml-10 mt-10">
          <Link className="mt-2" to="/" onClick={toggleMenu}>
            Home
          </Link>
          <Link
            className="mt-2"
            to="/credits"
            onClick={() => toggleMenu("blurred")}
          >
            {t("navbar_credits")}
          </Link>
          <Link
            className="mt-2"
            to="/contacts"
            onClick={() => toggleMenu("blurred")}
          >
            {t("navbar_contacts")}
          </Link>
          <a
            target="_blank"
            href="https://www.msantanna.it/shop/shop"
            className="mt-2"
            onClick={toggleMenu}
          >
           {t("navbar_shop")}
          </a>
          <Link
            className="mt-2"
            to="/aenaria"
            onClick={() => toggleMenu("blurred")}
          >
            {t("navbar_aenaria")}
          </Link>
        </div>
        <div className="flex-row justify-between navbar-footer ml-1 mt-auto">
          <p>
            Marina di sant'Anna s.r.l. Via San Giovan Giuseppe della Croce, 73,
            80077 Ischia (NA)
          </p>
          <Languageselector/>
        </div>
      </div>
      <div className="blur" ref={blur} />
    </div>
  );
}
export default withNamespaces()(Navbar);