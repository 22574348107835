import "./assets/style/basic.css";
import privacypdf from "./assets/pdf/privacy.pdf";
import Footer from "./component/Footer";
export default function Contacts() {
  return (
    <div>
      <div className="page-container flex center column">
        <div className="text-container align-center">
        <div className="title">CONTACTS</div>
          <p>
            email: info@marinadisantanna.it
          </p>
          <p>
            tel: +39 081985510
          </p>
          <p>
          Via San Giovan Giuseppe della Croce, 73, 80077 Ischia (NA)
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
