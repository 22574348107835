import "./assets/style/basic.css";
import LogoCampania from "./assets/image/logo_regione_rev.png";
import LogoRepubblica from "./assets/image/logo-rep-italiana.png";
import LogoUnioneEuropea from "./assets/image/logo-unione-europea.png";
import LogoFesr from "./assets/image/logofesr14-20.png";
import LogoPoc from "./assets/image/poc2020.png";
import Footer from "./component/Footer";
import { withNamespaces } from 'react-i18next';
import aiutipdf from "./assets/pdf/msantanna_aiutidistato.pdf"
function Sponsor({t}) {
  return (
    <div>
    <div className="page-container flex center column">
      <div className="text-container">
        <p>
          {t("credits_p1")}
        </p>
        <p>
        {t("credits_p2")}
        </p>
        <p>
        {t("credits_p3")}
        </p>
        <p>
        {t("credits_p4")}
        </p>
        <p className="align-center">{t("credits_p5")}</p>
        <a className="small_a_blue align-center" target="_blank" href={aiutipdf}>{t("credits_pdf")}</a>
      </div>
      <div className="flex center">

      </div>
      <div className="loghi-sponsor">
        <div className="flex center">
          <img className="loghi_sponsor" src={LogoUnioneEuropea} />
          <img className="loghi_sponsor" src={LogoRepubblica} />
          <img className="loghi_sponsor" src={LogoCampania} />
          <img className="loghi_sponsor" src={LogoFesr} />
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
}
export default withNamespaces()(Sponsor);