import "./assets/style/basic.css";
import React, { useState, useEffect } from "react";
import Footer from "./component/Footer";
import Fourimage from "./component/Fourimage";
import BuyButton from "./component/BuyButton";
import { withNamespaces } from 'react-i18next';
function Aenaria({t}) {
  const [images, setimages] = useState(null);

  function importAll(r) {
    let imagesvector = {};
    r.keys().map((item, index) => {
      imagesvector[item.replace("./", "")] = r(item);
    });
    return imagesvector;
  }
  useEffect(() => {
    setimages(
      importAll(
        require.context(
          "./assets/image/aenariapage",
          false,
          /\.(png|jpe?g|svg)$/
        )
      )
    );
  }, []);

  if (images != null) {
    return (
      <div>
        <div className="page-container flex center column">
          <div className="text-container">
            <BuyButton
              link="http://www.marinadisantanna.it/shop/product/aenaria-archaeological-tour/"
              price="30"
            />
            <div className="title">{t("aenaria_title")}</div>
            <p>
            {t("aenaria_p1")}
            </p>
            <p>
            {t("aenaria_p2")}
            </p>
            <div className="subtitle">
            {t("aenaria_subtitle1")}
            </div>
            <p>
            {t("aenaria_p3")}
            </p>
            <div className="ptitle">
            {t("aenaria_subtitle2")}
            </div>
            <p>
            {t("aenaria_p4")}
            </p>
            <Fourimage
              firstimage={images["aenaria_1.jpeg"]["default"]}
              secondimage={images["aenaria_2.jpeg"]["default"]}
              thirdimage={images["aenaria_3.jpeg"]["default"]}
              fourthimage={images["aenaria_4.jpeg"]["default"]}
            />
            <div className="subtitle">
            {t("aenaria_subtitle3")}
            </div>
            <p>
            {t("aenaria_p5")}
            </p>
            <Fourimage
              firstimage={images["aenaria_5.jpeg"]["default"]}
              secondimage={images["aenaria_6.jpeg"]["default"]}
              thirdimage={images["aenaria_7.jpeg"]["default"]}
              fourthimage={images["aenaria_8.jpeg"]["default"]}
            />
            <div className="subtitle">
            {t("aenaria_subtitle4")}
            </div>
            <p>
            {t("aenaria_p6")}
            </p>
            <Fourimage
              firstimage={images["aenaria_9.jpeg"]["default"]}
              secondimage={images["aenaria_10.jpeg"]["default"]}
              thirdimage={images["aenaria_11.jpeg"]["default"]}
              fourthimage={images["aenaria_12.jpeg"]["default"]}
            />
            <div className="subtitle">{t("aenaria_subtitle5")}</div>
            <p>
            {t("aenaria_p7")}
              <ul>
                <li>
                {t("aenaria_p7_li1")}
                </li>
                <li>
                {t("aenaria_p7_li2")}
                </li>
                <li>
                {t("aenaria_p7_li3")}
                </li>
                <li>
                {t("aenaria_p7_li4")}
                </li>
              </ul>
            </p>
            <Fourimage
              firstimage={images["aenaria_13.jpeg"]["default"]}
              secondimage={images["aenaria_14.jpeg"]["default"]}
              thirdimage={images["aenaria_15.jpeg"]["default"]}
              fourthimage={images["aenaria_16.jpeg"]["default"]}
            />
            <BuyButton
              link="http://www.marinadisantanna.it/shop/product/aenaria-archaeological-tour/"
              price="30"
            />
          </div>
        </div>
        <Footer />
      </div>
    );
  } else {
    return <div>LOADING</div>;
  }
}
export default withNamespaces()(Aenaria);
