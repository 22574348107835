import {useEffect, useState} from "react"
import Service from "./component/Service.js"
export default function CulturalTourism(){
 return <div className="flex center">
     <Service title="visitaischiaponte_title" subtitle="visitaischiaponte_subtitle" description="visitaischiaponte_description" image={require(`./assets/image/serviceimage/visitaischiaponte.jpeg`).default}/>
     <Service title="coloridelborgo_title" subtitle="coloridelborgo_subtitle" description="coloridelborgo_description" image={require(`./assets/image/serviceimage/coloridelborgo.jpeg`).default}/>
     <Service title="offertadellascuola_title" subtitle="offertadellascuola_subtitle" description="offertadellascuola_description" image={require(`./assets/image/serviceimage/offertadellascuola.jpeg`).default}/>
     <Service title="giroisola_title" subtitle="giroisola_subtitle" description="giroisola_description" image={require(`./assets/image/serviceimage/giroisola.jpeg`).default}/>
     <Service title="snorkeling_title" subtitle="snorkeling_subtitle" description="snorkeling_description" buyable={true} startingprice="40" link="https://www.msantanna.it/shop/product/snorkeling/" image={require(`./assets/image/serviceimage/snorkeling.jpeg`).default}/>
     <Service title="castelloaragonese_title" subtitle="castelloaragonese_subtitle" description="castelloaragonese_description" image={require(`./assets/image/serviceimage/castelloaragonese.jpeg`).default}/>
 </div>   
}