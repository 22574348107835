import "./style/footer.css";
import LogoCampania from "../assets/image/logo_regione_rev.png";
import LogoRepubblica from "../assets/image/logo-rep-italiana.png";
import LogoUnioneEuropea from "../assets/image/logo-unione-europea.png";
import LogoFesr from "../assets/image/logofesr14-20.png";
import LogoPoc from "../assets/image/poc2020.png";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import Languageselector from "./Languageselector";
import {useEffect} from "react";
function Footer({ t }) {
  useEffect(() => {
    window.scrollTo(0,0);
  }, [])
  return (
    <div className="footer-container w-100vw flex-column">
      <div className="footer-menu flex-row align-center flex-wrap justify-center">
        <Link to="/">Home</Link>
        <Link to="/credits">Credits</Link>
        <a target="_blank" href="https://www.msantanna.it/shop/shop">
          Shop
        </a>
        <Link to="/policy">Privacy&Policy</Link>
      </div>
      <div className="flex-column flex-wrap justify-center">
        <div className="flex-row justify-center">
          <div className="loghi_sponsor_group align-center">
            <img className="loghi_sponsor_footer" src={LogoUnioneEuropea} />
            <img className="loghi_sponsor_footer" src={LogoRepubblica} />
            <img className="loghi_sponsor_footer" src={LogoCampania} />
            <img className="loghi_sponsor_footer" src={LogoFesr} />
          </div>
        </div>
        <div className="flex-row flex-wrap justify-center sponsor_disclaimer">
          <div className="align-center">{t("footer_advice")}</div>
          <Link to="/credits" className="abuttonlike">
            {t("footer_gotopage")}
          </Link>
        </div>
      </div>
      <div className="align-center">
      <Languageselector/>
      </div>
      <div className="footer-info align-center">
        Marina di Sant'Anna s.r.l. | Via San Giovan Giuseppe della Croce, 73,
        80077 Ischia (NA) | email info@marinadisantanna.it | tel. +39 081985510 | P.IVA: 04196531216
      </div>
    </div>
  );
}
export default withNamespaces()(Footer);
