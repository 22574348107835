import { Container, Row, Col, Image } from "react-bootstrap";
import Footer from "./component/Footer";
import Navbar from "./component/Navbar";
import background1 from "./assets/image/castello.jpeg";
import Banner from "./component/Banner";
import Sponsor from "./Sponsor";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import CookiesConsent from "./component/Cookies";
import Whoweare from "./component/Whoweare";
import Aenaria from "./Aenaria"
import Policy from "./Policy"
import { withNamespaces } from 'react-i18next';
import CulturalTourism from "./CulturalTourism";
import Contacts from "./Contacts";

function App({ t }) {
  return (
    <div className="App">
      <Router>
      <Navbar />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/credits">
            <Sponsor />
          </Route>
          <Route path="/aenaria">
            <Aenaria />
          </Route>
          <Route path="/policy">
            <Policy />
          </Route>
          <Route path="/contacts">
            <Contacts />
          </Route>
          <Route path="/cultural_tourism">
          <CulturalTourism/>
          </Route>
        </Switch>
        <CookiesConsent/>
      </Router>
    </div>
  );
}

function Home() {
  return (
    <Container fluid="md">
      <Row>
        <Col>
          <Banner />
        </Col>
      </Row>
      <Whoweare/>
      <Footer />
    </Container>
  );
}

export default withNamespaces()(App);
