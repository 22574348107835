import Logo from "./Logo";
import "./style/whoweare.css";
import { withNamespaces } from 'react-i18next';
function Whoweare({t}) {
  return (
    <div className="w-100vw h-100vh flex-column  justify-center">
      <Logo size="logo-10" />
      <div className="container-text align-center">
        <p>
          {t('msantanna_description')}
        </p>
      </div>
    </div>
  );
}
export default withNamespaces()(Whoweare);