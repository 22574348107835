import { withNamespaces } from 'react-i18next';
import i18n from '../i18n';
import italy from "../assets/image/italy.png"
import uk from "../assets/image/uk.png"
import "./style/languagesel.css"
function Languageselector({t}){
const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  }
  return <div>
      <img onClick={()=> changeLanguage("it")} src={italy}/>
      <img onClick={()=> changeLanguage("en")} src={uk}/>
  </div>
}
export default withNamespaces() (Languageselector);
