import "./style/basic.css";
import "./style/buybutton.css";
import { withNamespaces } from 'react-i18next';
function BuyButton({ price, link, t}) {
  return (
    <div className="buybutton">
      <a target="_blank" href={link}>
        <div className="text">{t("buybutton_booknow")}</div>
        <div className="startingfrom">{t("buybutton_startfrom")}</div>
        <div className="price">{price}€</div>
      </a>
    </div>
  );
}
export default withNamespaces()(BuyButton);