import { useRef } from "react";
import { withNamespaces } from "react-i18next";
import BuyButton from "./BuyButton";
import "./style/service.css";
function Service({
  t,
  title,
  subtitle,
  image,
  description,
  buyable,
  startingprice,
  link,
}) {
  const hit = useRef(null);
  const toogledropdown = () => {
    if (hit.current.classList.contains("dropdown_on")) {
      hit.current.classList.remove("dropdown_on");
    } else {
      hit.current.classList.add("dropdown_on");
    }
  };
  if (buyable) {
    return (
      <div onClick={() => toogledropdown()} className="service_container">
        <img src={image} />
        <p className="title">{t(`${title}`)}</p>
        <div className="service_dropdown" ref={hit}>
          <p>{t("backtoservice")}</p>
          <p className="subtitle">{t(`${subtitle}`)}</p>
          <img src={image} />
          <p>{t(`${description}`)}</p>
          <BuyButton link={link} price={startingprice} />
        </div>
      </div>
    );
  } else {
    return (
      <div onClick={() => toogledropdown()} className="service_container">
        <img src={image} />
        <p className="title">{t(`${title}`)}</p>
        <div className="service_dropdown" ref={hit}>
          <p>{t("backtoservice")}</p>
          <p className="subtitle">{t(`${subtitle}`)}</p>
          <img src={image} />
          <p>{t(`${description}`)}</p>
        </div>
      </div>
    );
  }
}

export default withNamespaces()(Service);
