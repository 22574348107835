import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { Button } from "react-bootstrap";
import "./style/basic.css";
import "./style/cookies.css";
import ReactGA from "react-ga";

export default function CookiesConsent() {
  const [cookieconsent, setCookieConsent] = useState(false);
  useEffect(() => {
    if (cookieconsent == true) {
      Cookies.set("cookieconsent", true);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
    if (Cookies.get("cookieconsent")) {
      setCookieConsent(true);
      ReactGA.initialize("G-YNL414MTQG");
      
    }
  }, [cookieconsent]);
  if (cookieconsent == true) {
    return <div></div>;
  } else {
    return (
      <div className="cookieconsent-container">
        <div className="cookieconsent flex-row">
          <div className="flex-column ">
            <p className="align-center">
              We use cookies and similar methods to recognize visitors and
              remember their preferences. We also use them to measure ad
              campaign effectiveness, target ads and analyze site traffic. To
              learn more about these methods, including how to disable them
              click on this link{" "}
              <Link className="privacypolicylink" path="/privacyandpolicy">
                Privacy&Policy
              </Link>
            </p>
            <p className="align-center">
              By clicking "Accept" you consent to use the cookies
            </p>
          </div>
          <Button
            className="cookieconsent-button"
            onClick={() => {
              setCookieConsent(true);
            }}
          >
            Accept
          </Button>
        </div>
      </div>
    );
  }
}
