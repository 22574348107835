import "./assets/style/basic.css";
import privacypdf from "./assets/pdf/privacy.pdf"
import Footer from "./component/Footer"
export default function Policy() {
  return (
    <div>
      <div className="page-container flex center column">
        <div className="text-container">
            <a className="small_a" target="_blank" href={privacypdf}>SCARICA LA VERSIONE PDF</a>
          <p>
          Informativa sul trattamento dei dati personali
Marina di Sant’Anna S.R.L., con sede legale in Via San Giovan Giuseppe della croce, 73, 80077 Ischia (NA) Italia, P. IVA 04196531216 (in seguito, “Titolare”), in qualità di
titolare del trattamento, La informa ai sensi dell’art. 13 D.Lgs. 30.6.2003 n. 196 (in seguito, “Codice Privacy”) e dell’art. 13 Regolamento UE n. 2016/679 (in seguito,
“GDPR”) che i Suoi dati saranno trattati con le modalità e per le finalità seguenti:
</p><p>
1. Oggetto del trattamento
Il Titolare tratta i dati personali, identificativi (ad esempio, nome, cognome, ragione sociale, indirizzo, telefono, e-mail, riferimenti bancari e di pagamento – in seguito,
“dati personali” o anche “dati”) da Lei comunicati in occasione della conclusione di contratti per i servizi del Titolare.
</p><p>
2. Finalità del trattamento
I Suoi dati personali sono trattati senza il Suo consenso espresso (art. 24 lett. a), b), c) Codice Privacy e art. 6 lett. b), e) GDPR), per le seguenti Finalità di Servizio:
- concludere i contratti per i servizi del Titolare;
- adempiere agli obblighi precontrattuali, contrattuali e fiscali derivanti da rapporti con Lei in essere;
- adempiere agli obblighi previsti dalla legge, da un regolamento, dalla normativa comunitaria o da un ordine dell’Autorità (come ad esempio in materia di
antiriciclaggio);
- esercitare i diritti del Titolare, ad esempio il diritto di difesa in giudizio.
</p><p>3. Modalità di trattamento
Il trattamento dei Suoi dati personali è realizzato per mezzo delle operazioni indicate all’ art. 4 Codice Privacy e dell’art. 4 n. 2) GDPR e precisamente: raccolta,
registrazione, organizzazione, conservazione, consultazione, elaborazione, modificazione, selezione, estrazione, raffronto, utilizzo, interconnessione, blocco,
comunicazione, cancellazione e distruzione dei dati. I Suoi dati personali sono sottoposti a trattamento sia cartaceo che elettronico e/o automatizzato.
Il Titolare tratterà i dati personali per il tempo necessario per adempiere alle finalità di cui sopra e comunque per non oltre 10 anni dalla cessazione del rapporto per le
Finalità di Servizio.
</p><p>4. Accesso ai dati
I Suoi dati potranno essere resi accettabili per le finalità di cui all’art. 2:
- a dipendenti e collaboratori del Titolare, nella loro qualità di incaricati e/o responsabili interni del trattamento e/o amministrazioni di sistema;
- a società terze o altri soggetti (a titolo indicativo, istituti di credito, studi professionali, consulenti, società di assicurazione per la prestazione di servizi assicurativi, etc.)
che svolgono attività in “outsourcing” (esternalizzazione) per conto del Titolari, nella loro qualità di responsabili esterni del trattamento.
</p><p>5. Comunicazione dei dati
Senza la necessità di un espresso consenso (ex art. 24 lett. a), b), d) Codice Privacy e art. 6 lett. b) e c) GDPR), il Titolare potrà comunicare i Suoi dati per le finalità di cui
all’art. 2 a Organismi di vigilanza (quali IVASS), Autorità giudiziarie, a società di assicurazione per la prestazione di servizi assicurativi, nonché a quei soggetti ai quali la
comunicazione sia obbligatoria per legge per l’espletamento delle finalità dette. Detti soggetti tratteranno i dati nella loro qualità di autonomi titolari del trattamento.
I Suoi dati non saranno diffusi.
</p><p>6. Sicurezza
I dati vengono custoditi e controllati mediante adozione di idonee misure preventive di sicurezza, volte a ridurre al minimo i rischi di perdita e distruzione, di accesso non
autorizzato, di trattamento non consentito e difforme dalle finalità per cui il trattamento viene effettuato.
</p><p>7. Trasferimento dati
La gestione e la conservazione dei dati personali avverrà nel territorio dell’Unione Europea
</p><p>8. Diritti dell’interessato
Nella Sua qualità di interessato, ha il diritto di cui all’art. 15 GDPR e precisamente i diritti di:
I. ottenere la conferma dell’esistenza o meno di dati personali che La riguardano, anche se non ancora registrati, e la loro comunicazione in forma intelligibile;
II. ottenere l’indicazione: a) dell’origine dei dati personali; b) delle finalità e modalità del trattamento; c) della logica applicata in caso di trattamento effettuato con
l’ausilio di strumenti elettronici; d) degli estremi identificativi del titolare, dei responsabili e del rappresentante designato ai sensi dell’art. 5, comma 2 Codice Privacy e
art. 3, comma 1, GDPR; e) dei soggetti o delle categorie di soggetti ai quali i dati personali possono essere comunicati o che possono essere comunicati o che possono
venirne a conoscenza in qualità di rappresentante designato nel territorio dello Stato, di responsabili o incaricati;
III. ottenere: a) l’aggiornamento, la rettifica ovvero l’integrazione dei dati; b) la cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati in violazione
di legge, compresi quelli di cui non è necessaria la conservazione in relazione agli scopi per i quali i dati sono stati raccolti o successivamente trattati; c) l’attestazione che
le operazioni di cui alle lettere a) e b) sono state portate a conoscenza, anche per quanto riguarda il loro contenuto, di coloro ai quali i dati sono stati comunicati o diffusi,
eccettuato il caso in cui tale adempimento si rivela impossibile o comporta un impiego di mezzi manifestamente sproporzionato rispetto al diritto tutelato;
IV. opporsi, in tutto o in parte per motivi legittimi al trattamento dei dati personali che La riguardano, ancorché pertinenti allo scopo della raccolta.
Ove applicabili, Lei ha altresì i diritti di cui agli art. 16-21 GDPR (Diritto di rettifica, diritto all’oblio, diritto di limitazione di trattamento, diritto alla portabilità dei dati,
diritto di opposizione), nonché il diritto di reclamo all’Autorità Garante.
</p><p>9. Modalità di esercizio dei diritti
</p><p>
Potrà in qualsiasi momento esercitare i diritti inviando una comunicazione:
1. via e-mail, all’indirizzo: info@marinadisantanna.it
2. oppure via posta A. R., a: Marina di Sant’Anna S.R.L., Via San Giovan Giuseppe della croce, 73, 80077 Ischia (NA) Italia
10. Titolare, responsabile e incaricati
Il Titolare del trattamento è Marina di Sant’Anna S.R.L.
L’elenco aggiornato dei responsabili e degli incaricati al trattamento è custodito ed è consultabile presso la sede o ai contatti sopra citati del Titolare del trattamento
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
