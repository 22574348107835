import "./style/basic.css";
import "./style/fourimage.css";
export default function Fourimage(props) {
  return <div className="flex-row flex-wrap justify-center fourimage-container">
      <img src={props.firstimage}/>
      <img src={props.secondimage}/>
      <img src={props.thirdimage}/>
      <img src={props.fourthimage}/>
  </div>;
}
